import React from 'react'
import { graphql } from 'gatsby'
import Layout from 'layouts/zh'
import {
  Seo,
  Banner,
  SummaryTitle,
  TabSwitcher,
  Button,
  TestimonyCard,
  ImagePreview,
  ScanCodeConsultButton,
  MoreResources,
  ProductCards,
  PageBottomCard,
} from 'components/index'
import classNames from 'classnames'
import { RedirectTip } from 'components/HealthCom/MoreResources/index'
import useIsWindowWidthSmaller from 'hooks/useWindowWidth'
import gamePreview from './img/gamePreview.png'
import consultQrCode from './img/consultQrCode.jpg'

import * as styles from './index.module.less'

const Part4Item = ({ img, title, desc }) => {
  return (
    <div className={styles.cardWrapper}>
      <div className={styles.imgWrapper}>
        <img src={img?.publicURL ?? img} alt="" />
      </div>
      <div className={styles.cardCotent}>
        <div className={styles.cardTitle}>{title}</div>
        <div className={styles.cardDesc}>{desc}</div>
      </div>
    </div>
  )
}

const Game = (props) => {
  const { data } = props
  const { gameYaml } = data
  const { part1, part3, part4, part5, part6 } = gameYaml

  const isMb = useIsWindowWidthSmaller()

  const linkCardWords = {
    title: '神策游戏云，创意护航计划',
    desc1: '创意可以被工业化辅助。该计划由神策数据发起，致力于为每个游戏提供',
    highLightDesc: '小成本、高可用性的数据中台，',
    desc2: '助力游戏研发加速！',
  }

  const productCardsData = ['分析引擎', '受众策略引擎', 'A/B 测试', '广告助手']

  return (
    <Layout {...props}>
      <Seo
        title="神策游戏云 | 游戏运营数据分析平台"
        description="神策游戏云作为游戏运营数据分析平台，提供分析、受众、策略三大引擎，支持全品类游戏数据分析，帮助游戏公司精准洞察用户全生命周期行为，实现游戏精品化研运，打造爆款游戏。"
        keywords="游戏数据分析,游戏运营,游戏玩家分析,用户运营,用户行为分析,用户画像"
      />

      <div className={styles.gameRoot}>
        <div className={styles.bannerWrapper}>
          <Banner
            title={part1?.title}
            desc={part1?.desc}
            buttons={part1?.buttons}
            // img={part1.banner?.publicURL ?? part1.banner}
            className={classNames(styles.banner)}
            h1tag={true}
          />
        </div>
        <div className={classNames(styles.wWarrper, { [styles.mbPart2]: isMb, [styles.part2]: !isMb })}>
          {isMb ? (
            <div className={styles.mbLinkCard}>
              <div className={styles.linkCard}>
                <div className={styles.content}>
                  <div className={styles.title}>{linkCardWords.title}</div>
                  <div className={styles.desc}>
                    {linkCardWords.desc1}
                    <span className={styles.highLightDesc}>{linkCardWords.highLightDesc}</span>
                    {linkCardWords.desc2}
                  </div>
                  <div className="mt-[2.8rem] w-[6rem] lg:mt-[24px] lg:w-[80px]">
                    <RedirectTip text="立即申请" url="/gameActivity.html" />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className={styles.linkCard}>
              <div className={styles.content}>
                <div className={styles.title}>{linkCardWords.title}</div>
                <div className={styles.desc}>
                  {linkCardWords.desc1}
                  <span className={styles.highLightDesc}>{linkCardWords.highLightDesc}</span>
                  {linkCardWords.desc2}
                </div>
                <div className="mt-[24px] w-[80px]">
                  <RedirectTip text="立即申请" url="/gameActivity.html" />
                </div>
              </div>
              <div className={styles.bg}></div>
            </div>
          )}
          {/**cardLink 移动端向上移了3rem，所以此处的mt只需要设置为3rem视觉上就是6rem（设计图是6rem） */}
          <div className="md:mt-[80px] mt-[3rem]">
            {isMb ? (
              <div>
                <SummaryTitle title="分析、受众、策略三引擎" />
                <SummaryTitle title="加速游戏研运升级" />
              </div>
            ) : (
              <SummaryTitle title="分析、受众、策略三引擎，加速游戏研运升级" />
            )}
            <div className={styles.partContent}>
              <TabSwitcher
                data={part3}
                align="flex-start"
                fixedWandH
                mbShowShadow={false}
                itemContenWrapperClass={styles.itemContenWrapperClass}
              />
            </div>
          </div>
        </div>
        <div className={styles.bWarrper}>
          <SummaryTitle title="为什么选择神策" />
          <div className={`${styles.part4List} flex justify-center`}>
            {part4.map((item) => (
              <Part4Item {...item} key={item?.title} />
            ))}
          </div>
          <div className="flex justify-center md:mt-[30px] mt-[3rem] ">
            <Button btnType="primary" href="/doGameGroup">
              体验 Demo
            </Button>
          </div>
        </div>
        <div className={styles.wWarrper}>
          <SummaryTitle title="客户证言" />
          <div className="flex justify-center md:mt-[60px] mt-[3rem] pl-[20px] pr-[20px]">
            <TestimonyCard {...part5} signatureClass={styles.signatureClass} />
          </div>
        </div>
        <div className={styles.bWarrper}>
          <SummaryTitle title="游戏云全景图" desc="提速增效，告别多人协作“卡顿”" />
          <div className="flex justify-center md:mt-[60px] mt-[3rem] px-[2rem]">
            <div className="lg:w-[1047px]">
              <ImagePreview imageUrl={gamePreview} alt="游戏云全景图" />
            </div>
          </div>
          <div className="flex justify-center md:mt-[60px] mt-[3rem] ">
            <ScanCodeConsultButton buttonText="立即咨询" imgBottomText="立即咨询" qrcode={consultQrCode} />
          </div>
        </div>
        <div className={styles.wWarrper}>
          <SummaryTitle title="更多干货" />
          <div className="flex justify-center md:mt-[60px] mt-[3rem] pl-[20px] pr-[20px]">
            <MoreResources data={part6} mRleftClass={styles.mRleftClass} />
          </div>
        </div>
        <div className={styles.bWarrper}>
          <SummaryTitle title="神策游戏云，支持游戏研运的整体产品" />
          <div className="flex justify-center md:mt-[60px] mt-[3rem] pl-[20px] pr-[20px]">
            <ProductCards data={productCardsData} />
          </div>
        </div>
        <div className="pt-[4rem] md:pt-[100px] bg-[#f9fafc] md:bg-white">
          <PageBottomCard
            title="开启大数据分析与营销科技之旅！"
            desc="立即注册，和神策数据专家一起探讨数字化！"
            leftButtonText="体验 Demo"
            leftButtonHref="/demo/demo.html"
            rightButtonText="预约演示"
            rightButtonHref="/form/parade.html"
            type="newBg"
          />
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    gameYaml {
      part1 {
        title
        desc
        buttons {
          text
          href
          btnType
          ghost
        }
      }
      part3 {
        title
        sortTitle
        content {
          img {
            publicURL
          }
          alt
          introduction1
          mbIntroduction1
          buttons {
            btnType
            href
            text
          }
          introductionList {
            content
          }
          mbIntroductionList {
            content
          }
        }
      }
      part4 {
        title
        desc
        img {
          publicURL
        }
      }
      part5 {
        imgUrl {
          publicURL
        }
        imgAlt
        title
        text
        signature
      }
      part6 {
        title
        tip
        url
        img {
          publicURL
        }
        img_mb {
          publicURL
        }
        alt
        text
      }
    }
  }
`

export default Game
