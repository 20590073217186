// extracted by mini-css-extract-plugin
export var bWarrper = "index-module--bWarrper--TEMDf";
export var banner = "index-module--banner--dLvAu";
export var bannerWrapper = "index-module--bannerWrapper--l0YMj";
export var bg = "index-module--bg--McF6m";
export var cardCotent = "index-module--cardCotent--RF32f";
export var cardDesc = "index-module--cardDesc--fm6x-";
export var cardTitle = "index-module--cardTitle--sy-ZT";
export var cardWrapper = "index-module--cardWrapper--+pNY+";
export var content = "index-module--content--SM9kP";
export var desc = "index-module--desc--Ksnda";
export var gameRoot = "index-module--gameRoot--0SJFp";
export var highLightDesc = "index-module--highLightDesc--SoEP-";
export var imgWrapper = "index-module--imgWrapper--hxzzq";
export var itemContenWrapperClass = "index-module--itemContenWrapperClass--f0ngT";
export var linkCard = "index-module--linkCard--kkIDL";
export var mRleftClass = "index-module--mRleftClass--6NHYP";
export var mbLinkCard = "index-module--mbLinkCard--EtjiU";
export var mbPart2 = "index-module--mbPart2--AXqPb";
export var part2 = "index-module--part2--zrkMY";
export var part4List = "index-module--part4List--nfjUw";
export var partContent = "index-module--partContent--HfleK";
export var signatureClass = "index-module--signatureClass--OlXVl";
export var title = "index-module--title--bk4iW";
export var wWarrper = "index-module--wWarrper--vh+lJ";